import MenuIcon from '@mui/icons-material/Menu'
import { Button, IconButton, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material'
import { blue } from '@mui/material/colors'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/Logo2.svg'

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '10px' }}>
      {/* <div className="mobile-header-section"> */}
      <div className="mobile-header-logo">
        <Link to="/">
          <img src={Logo} width="150" height="56" alt="brand logo" className="mobile-header-logo" />
        </Link>
      </div>
      <div className="menu">
        <div className="">FAQs</div>
        <div className="">About us</div>
        <div className="">
          <Link asChild to="/">
            <Button sx={{ borderRadius: 20 }} color="primary" variant="outlined">
              <Typography fontWeight="500">The Ultra Creator</Typography>
            </Button>
          </Link>
        </div>
        <div className="menu-items" style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <Button
            sx={{
              backgroundColor: '#55585f',
              width: '127px',
              '&:hover': {
                backgroundColor: '#55585f',
              },
            }}
            variant="contained"
          >
            <Typography>Login</Typography>
          </Button>
          {/* <div>
            <KeyboardArrowDownIcon />
          </div> */}
        </div>
        {/* <div className="menu-items">How it works</div> */}
      </div>
      <div className="rl-menu">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleMenuClick}>
            <MenuIcon sx={{ color: blue[50] }} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>Home</MenuItem>
            <MenuItem onClick={handleMenuClose}>Get Started</MenuItem>
            <MenuItem onClick={handleMenuClose}>Login</MenuItem>
          </Menu>
        </Toolbar>
      </div>
    </Stack>
  )
}

export default Header
