import { Box, Container, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import FinancialData from '../assets/access_tool.png'
import Makeitrain from '../assets/earn_more.png'
import WorkingTogether from '../assets/making_partnership.png'

const FeatureBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  width: 'fit-content',
  padding: theme.spacing(2),
  maxWidth: 279,
  marginInline: 'auto',
}))

const FeatureImage = styled('img')({
  //   width: '100px',
  height: '100px',
  marginBottom: '16px',
  marginTop:'15px'
})

const TopFeaturesSection = () => {
  return (
    <Container maxWidth="xl" sx={{ pb: 11.5, mx: 'auto' }}>
      <Box textAlign="center" my={4}>
        <Typography variant="h4" gutterBottom>
          The Creator first influencer marketing platform
        </Typography>
        <Typography variant="subtitle1">Earn more doing what you love, while we take care of the rest.</Typography>
      </Box>
      <Grid sx={{ width: '100%' }} container justifyContent="space-between" spacing="2">
        <Grid item xs={12} sm={6} md={4} justifyContent="center">
          <FeatureBox>
            <Typography variant="h5" fontWeight="500" whiteSpace="nowrap">
              Meaningful Partnerships
            </Typography>
            <FeatureImage src={WorkingTogether} alt="Meaningful Partnerships" />

            <Typography variant="body1">Long-Term partnerships with the brands you love</Typography>
          </FeatureBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureBox>
            <Typography variant="h5" fontWeight="500">
              Earn More
            </Typography>
            <FeatureImage src={Makeitrain} alt="Earn More" />

            <Typography variant="body1">Start earning from your content with brand deals</Typography>
          </FeatureBox>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FeatureBox>
            <Typography variant="h5" fontWeight="500">
              Access to Tools
            </Typography>
            <FeatureImage src={FinancialData} alt="Access to Tools" />
            <Typography variant="body1">Analytics that gives you insights about your content</Typography>
          </FeatureBox>
        </Grid>
      </Grid>
    </Container>
  )
}

export default TopFeaturesSection
