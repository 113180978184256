import React, { useEffect } from 'react'
import HeroImage1 from '../assets/HeroImage1.svg'
import HeroImage2 from '../assets/HeroImage2.svg'
// import MenuIcon from "@mui/icons-material/Menu";
// import { blue } from "@mui/material/colors";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Logo from "../assets/Logo.svg";
// import { Toolbar, IconButton, Menu, MenuItem } from "@mui/material";
import Button from '@mui/material/Button'
import { motion } from 'framer-motion'

const transistionY = {
  hidden: { opacity: 0, y: 70 },
  show: {
    opacity: 1,
    y: 0,
    transition: { duration: 1 },
  },
}

const HeroSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleMenuClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };
  return (
    <>
      <div className="hero-container">
        <div className="hero-main-container">
          <div className="hero-text-section">
            <div className="text-container">
              <motion.div initial="hidden" animate="show" variants={transistionY} className="hero-title">
                The <span className="highlighted-title">Ultra</span> <br />
                Creator
              </motion.div>
              <motion.div initial="hidden" animate="show" variants={transistionY} className="description">
                New Age Creators driving campaigns for New Age Brands
                {/* <br /> */}
              </motion.div>
              <button className="get-started-btn">Get Started</button>
            </div>
          </div>
          <div className="hero-image-section">
            <div className="image1">
              <img src={HeroImage2} alt="hero" className="hero-image" />
            </div>
            <div className="image2">
              <img src={HeroImage1} alt="hero2" className="hero-image" />
            </div>
          </div>
        </div>
        <div className="responsive-layout">
          <div>
            <div className="rl-title">The</div>
            <div className="rl-container">
              <div className="rl-highlighter">Ultra</div>
            </div>

            <div className="rl-title">Creator</div>
            <div className="rl-btn-container">
              <Button variant="outlined" style={{ color: 'white', borderColor: 'white' }}>
                Get Started
              </Button>{' '}
            </div>
            <div className="hero-section-subText">
              New Age Creators driving <br />
              campaigns for New Age Brands
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection
