import { Box, Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import ConnectBrandImg from '../assets/connectbrand.png'
import { transistionY } from '../styles/animation'
import SectionContainer from './SectionContainer'

export default function ConnectWithBrands() {
  return (
    <div className="connect-brands-section ">
      <SectionContainer className="py-16">
        <motion.div
          initial={'hidden'}
          whileInView="show"
          viewport={{ once: true }}
          variants={transistionY}
          className="connect-brand-container"
        >
          <div className="connect-brand-left ">
            <h1 className="connect-brand-title">
              Connect with the brands, <span className="connect-brand-highlighted-text">You Love.</span>
            </h1>
            <Box sx={{ marginTop: '65px', marginBottom: '98px' }}>
              <Typography fontSize="24px">
                Get Access to the <span className=" font-bold">Exclusive </span>Brand Deals.
              </Typography>
              <Typography fontSize="19px" sx={{ marginTop: '43px' }}>
                Start using <span className="font-bold">The Ultra creator</span> specialized link-in-bio and performance
                analytics tools to enhance your campaigns with brands and gain an edge over other creators.
              </Typography>
            </Box>
            <Button className="connect-brand-btn" color="black" variant="contained">
              Get Started
            </Button>
            {/* <button className="connect-brand-btn">Get Started</button> */}
          </div>
          <div className="connect-brand-right ">
            <img src={ConnectBrandImg} alt="connect-brand" />
          </div>
        </motion.div>
      </SectionContainer>
    </div>
  )
}
