import React, { useEffect } from 'react'
import HeroImage from '../assets/the-ultra-brand-hero.svg'
// import MenuIcon from "@mui/icons-material/Menu";
// import { blue } from "@mui/material/colors";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Logo from "../assets/Logo.svg";
// import { Toolbar, IconButton, Menu, MenuItem } from "@mui/material";
import Button from '@mui/material/Button'
import { motion } from 'framer-motion'

const transistionY = {
  hidden: { opacity: 0, y: 70 },
  show: {
    opacity: 1,
    y: 0,
    transition: { duration: 1 },
  },
}

const HeroSection2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="hero-container2">
        <div className="hero-main-container">
          <div className="hero-text-section">
            <div className="text-container">
              <motion.div initial="hidden" animate="show" variants={transistionY} className="hero-title text-black">
                The <span className="highlighted-title text-white">Ultra</span> <br />
                Brand
              </motion.div>
              <motion.div initial="hidden" animate="show" variants={transistionY} className="description text-black">
                All in one tool to manage your influencer campaigns from discovery to analytics
                {/* <br /> */}
              </motion.div>
              <Button className="get-started-btn" variant="contained" color="black">
                Get Started
              </Button>
            </div>
          </div>
          <div className="hero-image-section">
            <div className="image1">
              <img src={HeroImage} alt="hero" className="hero-image" />
            </div>
          </div>
        </div>
        <div className="responsive-layout">
          <div>
            <div className="rl-title">The</div>
            <div className="rl-container">
              <div className="rl-highlighter">Ultra</div>
            </div>

            <div className="rl-title">Brand</div>
            <div className="rl-btn-container">
              <Button variant="outlined" style={{ color: 'white', borderColor: 'white' }}>
                Get Started
              </Button>{' '}
            </div>
            <div className="hero-section-subText">
              New Age Creators driving <br />
              campaigns for New Age Brands
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroSection2
