import React from 'react'

const ComingSoon = () => {
  return (
    <div className="coming-container">
      <div className="coming-soon-title">Launching Soon</div>
    </div>
  );
};

export default ComingSoon;