import React from 'react'
import ComingSoon from '../components/ComingSoon'
import HeroSection2 from '../components/HeroSection2'
import MobileHeader2 from '../components/MobilHeader2'
export default function TheUltimateBrand() {
  return (
    <>
      <ComingSoon />
      <MobileHeader2 />
      <HeroSection2 />
      <div className="bg-[#e4d7ff] h-screen"></div>
    </>
  )
}
