import { Box, Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import React from 'react'
import OnePlatformImg from '../assets/one-platform.png'
import { transistionY } from '../styles/animation'
import SectionContainer from './SectionContainer'

export default function OnePlatformSection() {
  return (
    <div className="one-platform-section ">
      <SectionContainer className=" py-16">
        <motion.div
          initial={'hidden'}
          whileInView="show"
          viewport={{ once: true }}
          variants={transistionY}
          className="one-platform-container"
        >
          <div className="one-platform-left">
            <h1 className="one-platform-title">
              One Platform, <span className="one-platform-highlighted-text">infinite opportunities.</span>
            </h1>
            <Box sx={{ marginTop: '65px', marginBottom: '98px' }}>
              <Typography fontSize="24px">
                Begin your journey with <span className=" font-bold">The Ultra Creator</span>
              </Typography>
              <Typography fontSize="19px" sx={{ marginTop: '43px' }}>
                Sign-up on <span className="font-bold">The Ultra creator</span> platform, Connect your social media
                account and get discovered by the leading brands.
              </Typography>
            </Box>
            <Button className="one-platform-btn" color="black" variant="contained">
              Get Started
            </Button>
          </div>
          <div className="one-platform-right">
            <img src={OnePlatformImg} alt="one-platform" />
          </div>
        </motion.div>
      </SectionContainer>
    </div>
  )
}
