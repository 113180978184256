import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import SectionContainer from './SectionContainer'

export default function NextColabaration() {
  return (
    <Box className="next-colab-section">
      <SectionContainer>
        <Stack py={10} spacing={2} direction="row" flexWrap="wrap" justifyContent="space-between" alignItems="center" useFlexGap>
          <Typography fontSize="40px" fontWeight="600">
            Your Next Brand Collaboration is one click away.
          </Typography>
          <Button className="next-colab-btn" color="black" variant="contained">
            Login
          </Button>
        </Stack>
      </SectionContainer>
    </Box>
  )
}
