import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div class="mx-auto px-5 mt-8 lg:mt-16 max-w-lg sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
      <div class="text-4xl not-italic font-bold font-sans text-oi-gray-900 tracking-wider mb-5">
        Privacy Policy
      </div>
      <div class="mb-5">
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          ULTRAGON VENTURES(OPC) PRIVATE LIMITED (referred as Company or “we” or
          “us” or “our") is the owner of the website domain at
          https://ultracreator.pro/ and other associated/ancillary applications,
          products, websites, subdomains, services and all other variations and
          sub-domains of the same (hereinafter referred to as “Platform”). The
          provision of the Services through the Platform is conditioned upon
          your acceptance of the terms and conditions contained in Terms of
          Service as available on Platform and this privacy policy (“
          <span class="font-bold">Privacy Policy</span>”).
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN
          ELECTRONIC CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT, 2000
          AND THE RULES MADE THEREUNDER AND THE PROVISIONS PERTAINING TO
          ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
          INFORMATION TECHNOLOGY ACT, 2000 OR ANY RELEVANT STATUTE OR REGULATION
          UNDER ANY APPLICABLE JURISDICTION. THIS PRIVACY POLICY DOES NOT
          REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          We are committed to keeping your personal data private and secure. We
          process any personal data we collect from you in accordance with the
          applicable laws and regulations and the provisions of this Privacy
          Policy. We have taken considerable efforts to ensure that we only
          collect minimal data from you and only to the extent necessary in
          order for us to provide you with Services.
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          The purpose of this Privacy Policy is to ensure that there is an
          intact charter to collect, use and protect any personal and/or
          sensitive data collected by us. This Privacy Policy defines our
          procedure for collection, usage, processing, disclosure and protection
          of any information obtained by us through the Platform. Capitalized
          terms that are not defined in this Privacy Policy shall have the same
          meaning as ascribed to them in our Terms of Service. Any reference
          made to Privacy Policy in this document shall mean and refer to the
          latest version of the Privacy Policy
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          CONSENT
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          THIS PRIVACY POLICY IS A LEGALLY BINDING DOCUMENT BETWEEN YOU AND THE
          COMPANY. THE TERMS OF THIS PRIVACY POLICY WILL BE EFFECTIVE UPON YOUR
          ACCEPTANCE OF THE TERMS OF SERVICE OR BY YOUR USE OF OUR SERVICES AND
          WILL GOVERN THE RELATIONSHIP BETWEEN COMPANY AND YOU WITH RESPECT TO
          THE COLLECTION AND TREATMENT OF INFORMATION SHARED BY YOU.
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          PLEASE READ THIS PRIVACY POLICY CAREFULLY AS IT AFFECTS YOUR RIGHTS
          AND LIABILITIES UNDER LAW. BY USING THIS PLATFORM AND AVAILING OUR
          SERVICES, YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS
          PRIVACY POLICY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY
          POLICY, PLEASE DO NOT USE THIS WEBSITE OR AVAIL OUR SERVICES.
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          Please be advised that any Information procured by us, shall be:
          <ul class="list-disc ml-4">
            <li>processed fairly and lawfully for rendering the Services;</li>
            <li>obtained only for specified and lawful purposes;</li>
            <li>
              adequate, relevant and not excessive in relation to the purpose
              for which it is required;
            </li>
            <li>
              able to be reviewed by the User, from time to time and updated-if
              need arises; and
            </li>
            <li>
              not kept longer than for the time which it is required or the
              purpose for which it is required or as required by the applicable
              law.
            </li>
          </ul>
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          If you do not agree with this Privacy Policy, you may refuse or
          withdraw your consent any time, or alternatively choose to not provide
          us with any Personal Information, you understand that under such
          circumstance, we may be unable to render Services. Any such intimation
          to withdraw your consent can be sent to&nbsp;
          <a
            href="mailto:ultragonventures@gmail.com"
            target="_blank"
            class="text-blue-600 underline undefined"
            rel="noreferrer"
          >
            ultragonventures@gmail.com
          </a>
          .
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE SUSTAINED BY REASON OF
          ANY DISCLOSURE (INADVERTENT OR OTHERWISE) OF ANY DATA, IF THE SAME IS
          EITHER (A) REQUIRED FOR SHARING YOUR INFORMATION FOR LEGITIMATE
          PURPOSES; OR WAS CAUSED THROUGH NO FAULT, ACT, OR OMISSION OF THE
          COMPANY.
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          TYPES OF INFORMATION COLLECTED BY US
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          <ul class="list-disc ml-4">
            <li>
              <span class="font-bold">“Personal Data”</span> means and includes
              any Information that relates to a natural person through which an
              individual is identified, such as the name, email id, country of
              origin or any other information relevant to product choice and
              preferences provided by a User, including but not limited to
              information gathered through availing Services.
            </li>
            <li>
              <span class="font-bold">“Sensitive Personal Data”</span> means and
              includes information relating to (i) financial information such as
              bank account or credit card or debit card or other payment
              instrument details of the Creator or Brand.
            </li>
            <li>
              <span class="font-bold">“Technical Information”</span> means and
              includes any Information gathered through various technologies
              that may employ cookies or similar technologies to automatically
              record certain information from your device through which you use
              the Platform. This technical information may include your Internet
              Protocol (IP) address.
            </li>
            <li>
              <span class="font-bold">“Non-Personal Information”</span> means
              and includes any information that does not reveal your specific
              identity, such as, browser information, information collected
              through Cookies (as defined below), pixel tags and other
              technologies, demographic information, etc. As is true with most
              websites, our Company gathers some information automatically when
              you visit the Platform. When you use the Platform, we may collect
              certain information about your computer or mobile to facilitate,
              evaluate and verify your use of the Platform. For example, we may
              store environmental variables, such as browser type, operating
              system, speed of the central processing unit (CPU), referring or
              exit web pages, click patterns. This information is generally
              collected in aggregate form, without identifying any User
              individually.
              <div class="mt-3">
                (The Personal Data, Sensitive Personal Data, Technical
                Information, and Non-Personal Information are collectively
                referred to as <span class="font-bold">“Information”</span>).
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          COLLECTION OF INFORMATION
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2 ml-4">
          <div class="flex flex-row">
            <div class="mr-5">3.1</div>
            <div>
              Information may be collected in various ways including during the
              course of your registration as a User on the Platform, or while
              availing certain Services offered on the Platform.
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-5">3.2</div>
            <div>
              We may receive Information about you from third-party platform,
              such as social media platforms, marketing and advertising firms,
              commercially available sources and business partners to whom you
              have consented disclosure of such Information as well as publicly
              available information available in the public domain and on the
              internet.
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-5">3.3</div>
            <div>
              (App's) use and transfer to any other app of information received
              from Google APIs will adhere to{" "}
              <a
                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                target="_blank"
                class="text-blue-600 underline undefined"
                rel="noreferrer"
              >
                Google API Services User Data Policy
              </a>
              , including the Limited Use requirements.
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-5">3.4</div>
            <div>
              Please do note that each category of Information may be treated
              differently as per this Privacy Policy.
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          PURPOSE FOR COLLECTING OF INFORMATION
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          <div class="flex flex-row">
            <div class="mr-5">4.1</div>
            <div>
              The Company collects, uses, stores and processes your Information
              for any purpose as may be permissible under applicable laws
              (including where the applicable law provides for such collection,
              usage, storage or processes in accordance with the consent of the
              User) and shall include the following:
              <div class="ml-4">
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">i)</div>
                  <div class="ml-4">
                    to render Services and facilitate your use of the Platform;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">ii)</div>
                  <div class="ml-4">
                    to respond to your inquiries or fulfil your requests for
                    information about the various Services offered on the
                    Platform;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">iii)</div>
                  <div class="ml-4">
                    to provide you with information about Services available on
                    the Platform and to send you information, materials, and
                    offers;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">iv)</div>
                  <div class="ml-4">
                    to send you important information regarding the Platform,
                    changes in terms and conditions, user agreements, and
                    policies and/or other administrative information;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">v)</div>
                  <div class="ml-4">
                    to send you surveys and marketing communications;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">vi)</div>
                  <div class="ml-4">to improve user experience</div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">vii)</div>
                  <div class="ml-4">
                    to help you address your problems with respect to incurred
                    on the Platform including addressing any technical problems;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">viii)</div>
                  <div class="ml-4">
                    to protect the integrity and for proper administering of the
                    Platform;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">ix)</div>
                  <div class="ml-4">
                    to conduct academic research, surveys, analytical studies on
                    various aspects including user behaviour, user preferences
                    etc.;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">x)</div>
                  <div class="ml-4">
                    to respond to legal, judicial, quasi-judicial process and
                    provide information to law enforcement agencies or in
                    connection with an investigation on matters related to
                    public safety, as permitted by law;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">xi)</div>
                  <div class="ml-4">
                    to implement information security practices;
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">xii)</div>
                  <div class="ml-4">
                    to determine any security breaches, computer contaminant or
                    computer virus,
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">xiii)</div>
                  <div class="ml-4">
                    to investigate, prevent, or take action regarding illegal
                    activities and suspected fraud.
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">xiv)</div>
                  <div class="ml-4">
                    to enable a potential buyer or investor to evaluate the
                    business of the Company.
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">xv)</div>
                  <div class="ml-4">
                    Business or Research Purposes: The Information saved (except
                    Sensitive Personal Information), is used for business or
                    research purposes, including improving and customizing the
                    Platform for ease of use and the products and services
                    offered by us. We may archive this information to use it for
                    future communications for providing updates and/or surveys.
                  </div>
                </div>
                <div class="flex w-4/5 flex-row">
                  <div class="w-4">xvi)</div>
                  <div class="ml-4">
                    <div>
                      Aggregating Information / Anonymized data: We may
                      aggregate Information and analyse it in a manner to
                      further accentuate the level of services that we offer to
                      our customers. This Information includes average number of
                      Users of the Platform, the average clicks of the services,
                      the features used, the response rate, etc. and other such
                      statistics regarding groups or individuals. In doing so,
                      we shall not be making disclosures of any Sensitive
                      Personal Information as defined above.
                    </div>
                    <div class="mt-4">
                      [Individually and collectively referred to as (
                      <span class="font-bold">"Legitimate Purposes"</span>)]
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          SHARING AND DISCLOSURE OF YOUR INFORMATION
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          <div class="flex flex-row">
            <div class="mr-5">5.1</div>
            <div>
              We do not rent, sell or disclose or share any Information that we
              collect from you, with third parties, save and except in order to
              provide you with the Services. Any such disclosure, if made, shall
              be in accordance to this Privacy Policy and as per the procedure
              prescribed by law and in compliance with our legal obligations. We
              may share your Information in circumstances and for the purposes
              as specified hereunder:
            </div>
          </div>
          <div class="flex flex-col ml-14">
            <div class="flex flex-row">
              <div class="mr-5">5.1.1</div>
              <div>
                We shall share the information to the third-party service
                providers/ vendors, to provide you with the Services.
              </div>
            </div>
            <div class="flex flex-row">
              <div class="mr-5">5.1.2</div>
              <div>
                We may disclose any Information provided by you on the Platform
                as may be deemed to be necessary or appropriate:
                <ul class="list-disc ml-4">
                  <li>for achieving the Legitimate Purposes set out above;</li>
                  <li>
                    under applicable law, including laws outside your country of
                    residence;
                  </li>
                  <li>to comply with legal process;</li>
                  <li>
                    to respond to requests from public and government
                    authorities outside your country of residence;
                  </li>
                  <li>
                    to protect our operations or those of any of our affiliates;
                  </li>
                  <li>
                    to protect our rights, privacy, safety or property, and/that
                    of our affiliates, you or others;
                  </li>
                  <li>
                    to allow us to pursue available remedies or limit the
                    damages that we may sustain;
                  </li>
                  <li>to protect against legal liability;</li>
                  <li>
                    to protect the personal safety of Users of the Platform; or
                  </li>
                  <li>
                    to prevent or investigate possible wrongdoing in connection
                    with the Platform.
                  </li>
                </ul>
              </div>
            </div>
            <div class="flex flex-row">
              <div class="mr-5">5.1.3</div>
              <div>
                Merger or Acquisition: We may share Information upon merger or
                acquisition of Company with another company. We shall transmit
                and transfer the Information upon acquisition or merger of
                Company with another company.
              </div>
            </div>
            <div class="flex flex-row">
              <div class="mr-5">5.1.4</div>
              <div>
                With our service providers: We may share Information with other
                service providers on a need to know basis, subject to
                obligations of confidentiality for provision of Services. We
                hereby clarify that we work with institutions, vendors,
                partners, advertisers, and other service providers, including
                (but not limited) to those who provide products or services such
                as contact Information verification, website hosting, data
                analysis, providing infrastructure, information technology
                services, auditing services and other similar services, in
                different industries and categories of business by virtue of
                lawful contracts instituted between such third parties and
                Company to improve our product and services. Accordingly, we may
                share your Information with such service provider in order to
                provide you with Services.
              </div>
            </div>
            <div class="flex flex-row">
              <div class="mr-5">5.1.5</div>
              <div>
                Employees /Agents of Company: We follow a strict confidentiality
                policy with regard to disclosure of confidential information to
                our employees or other personnel. There may be situations, where
                we may disclose the confidential information only to those of
                our employees and other personnel on a need to know basis. Any
                breach of confidential information by the employees, personnel
                within the Company is dealt with stringently by us.
              </div>
            </div>
            <div class="flex flex-row">
              <div class="mr-5">5.1.6</div>
              <div>
                Except for the Information disclosed pursuant to this section,
                Company may share Information only if you authorize us to do so.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          LINK TO OTHER WEBSITES AND THIRD-PARTY SERVICES
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          <div class="flex flex-row">
            <div class="mr-5">6.1</div>
            <div>
              Our Platform may provide links to other sites. These links are
              provided for your convenience only and the provision of these
              links does not mean that sites are related or associated with us.
              Please note that these sites have their own terms of use and
              privacy policy. You should check their privacy policy before you
              submit Your Personal Information or any other data with them. We
              don't guarantee the content and the security of those sites.
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-5">6.2</div>
            <div>
              We may have certain features on our Platform which may be hosted
              by third parties, your interaction with such features shall be
              governed by the privacy policy of such third parties.
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-5">6.3</div>
            <div>
              We shall not be responsible for any loss, damage, claim or expense
              caused as a result of you accessing these third-party sites and
              features.
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-5">6.4</div>
            <div>
              We may use your Information to send you promotional Information
              about third parties which, we think you may find interesting, if
              you tell us that you wish this to happen. We shall not be
              responsible for any disclosure of Information due to unauthorized
              third-party access or other acts of third parties or acts or
              omissions beyond our reasonable control and you agree that you
              will not hold us responsible for any breach of security unless
              such breach has been caused as a direct result of our negligence
              or wilful default.
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-5">6.5</div>
            <div>
              We use support services of third-party platforms and/or companies
              to direct you to payment gateways when you opt to pay for our
              Services. Your financial information is collected, stored and
              retained by such third-party platforms. We and such designated
              third-party platforms undertake measures designed to provide a
              security level that is appropriate to the risks of processing your
              personal information. However, you are requested to check and
              consent to the “Privacy Policy” of such third-party platforms in
              order to accept how such third-party platforms handle your
              Information.
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          CHILDREN'S PRIVACY
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          <div class="ml-4">
            Children below the age of 18 may register as Influencers on the
            Platform or may be requested by the Brand as a part of a Campaign.
            Any such registration and the use of the Platform by the child or
            their participation in a Campaign will be subject to verifiable
            consent of the child's parent or legal guardian.
          </div>
          <div class="ml-4 mt-2">
            If you are a parent or a legal guardian and you or your child wishes
            to associate with the Company, we request that you read the
            following section carefully:
          </div>
          <div class="ml-4 mt-2">
            For your child to have their own Account or to be onboarded for a
            Brand's Campaign, we may need your permission to collect, use or
            disclose your child's information as described in this Privacy
            Policy. When you allow your child to use our Services, you and your
            child are trusting us with your Personal Data. We understand this is
            a big responsibility and work hard to protect your information and
            put you in control.
          </div>
          <div class="ml-4 mt-2">
            Once you grant permission for your child to have an Account or
            profile or to be included in a Campaign, their Account will
            generally be treated like your own with respect to the information
            that we collect and all rights and obligations as stated in this
            Privacy Policy including with respect to consent, collection and
            disclosure will extend to you and your child.
          </div>
          <div class="ml-4 mt-2">
            At any time, parents can refuse to permit us to collect further
            Personal Data from their children in association with a particular
            Account or Campaign, and can request that we delete from our records
            the Personal Data we have collected in connection with that Account
            or Campaign. Please keep in mind that a request to delete records
            may lead to a termination of an Account, Campaign, subscription, or
            other service.
          </div>
          <div class="ml-4 mt-2">About Verifiable Parental Consent:</div>
          <div class="ml-4 mt-2">
            Email Consent. In the event that the Company wishes to collect
            Personal Data from a child during the Account creation or for those
            Influencers who do not have an Account it will be done at the time
            of Campaign confirmation, we will first seek a parent or legal
            guardian's consent by email verification. In the email we will
            explain what information we are collecting, how we plan to use it,
            how the parent can provide consent, and how the parent can revoke
            consent. If you do not provide parental consent by clicking on the
            link shared with you on the email, we will delete the parent contact
            information and any other information collected from the child in
            connection with that activity.
          </div>
          <div class="ml-4 mt-2">
            KYC: In the event that your child is taking part in a Campaign that
            involves a monetary transaction, we are mandated under law to verify
            your KYC prior to processing any such monetary incentives. In such a
            scenario, we would be conducting your KYC through our third-party
            service provider and in the event you do not consent to the same,
            you may reject the KYC request or reach out to us at&nbsp;
            <a
              href="mailto:ultragonventures@gmail.com"
              target="_blank"
              class="text-blue-600 underline undefined"
              rel="noreferrer"
            >
              ultragonventures@gmail.com
            </a>
          </div>
          <div class="ml-4 mt-2">
            Please be aware that to protect children's privacy and security, we
            will take reasonable steps to verify a parent or legal guardian's
            identity before granting access to any Personal Data.
          </div>
          <div class="ml-4 mt-2">
            If you are a parent or guardian and you believe that your child has
            provided us with Personal Data without your consent, please contact
            us as at&nbsp;
            <a
              href="mailto:ultragonventures@gmail.com"
              target="_blank"
              class="text-blue-600 underline undefined"
              rel="noreferrer"
            >
              ultragonventures@gmail.com
            </a>
            . We will dispose of that data in accordance with applicable laws
            and regulations.
          </div>
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          DATA PROTECTION RIGHTS
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          <div class="flex flex-row">
            <div class="mr-5">8.1</div>
            <div>
              You have certain rights when it comes to your Personal
              Information. Subject to any exemptions provided by the applicable
              laws, you have the following rights:
            </div>
          </div>
          <div class="flex ml-10">
            <div class="flex flex-row">
              <div class="mr-5">
                <span class="font-bold">8.1.1</span>
              </div>
              <div>
                <span class="font-bold">
                  Rectifying, correcting, updating and removing Your
                  information:
                </span>
                <div>
                  You can access, edit, modify and/or update your Personal
                  Information by logging into your user profile or you can write
                  to us via email in case you wish to exercise this right.
                </div>
              </div>
            </div>
          </div>
          <div class="flex ml-10">
            <div class="flex flex-row">
              <div class="mr-5">
                <span class="font-bold">8.1.2</span>
              </div>
              <div>
                <span class="font-bold">
                  Accessing and updating or deleting Your information:
                </span>
                <div>
                  Our Services and related documentation on our Platform provide
                  you with the ability to access, update and delete certain
                  Personal Information from your Account, if provided. We will
                  provide you with information about whether we hold any of your
                  Personal Information upon request. We will respond to such
                  requests within a reasonable timeframe. Please note, that we
                  may need to retain certain information for record keeping
                  purposes, to complete our Services and related obligations to
                  you or to comply with our legal obligations.
                </div>
              </div>
            </div>
          </div>
          <div class="flex ml-10">
            <div class="flex flex-row">
              <div class="mr-5">
                <span class="font-bold">8.1.3</span>
              </div>
              <div>
                <span class="font-bold">
                  Object or restrict processing of Your information:
                </span>
                <div>
                  You have the right to: (i) object to our processing of your
                  Personal Information; and/or (ii) request that we restrict the
                  processing of your Personal Information.
                </div>
              </div>
            </div>
          </div>
          <div class="flex ml-10">
            <div class="flex flex-row">
              <div class="mr-5">
                <span class="font-bold">8.1.4</span>
              </div>
              <div>
                <span class="font-bold">Portability:</span>
                <div>
                  You shall have the right to request us to transfer Your
                  Personal Information to another controller, or directly to
                  you, in a structured, commonly used and machine-readable
                  format.
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row">
            <div class="mr-5">8.2</div>
            <div>
              In order to exercise these rights, please contact us on&nbsp;
              <a
                href="tel:+91 9503182221"
                target="_blank"
                class="text-blue-600  undefined"
                rel="noreferrer"
              >
                +91 9503182221
              </a>
              .
            </div>
          </div>
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          STORAGE AND TRANSFER OF INFORMATION
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          Your Information will primarily be stored in electronic form; however
          that certain data can also be stored in physical form. We primarily
          store all Information within the territory of India. If your
          Information is transported outside the territorial jurisdiction of
          India, we will provide you due notice of such transfer. We may enter
          into agreements with third parties (in or outside of India) to store
          or process your information or data. These third parties may have
          their own security standards to safeguard your information or data and
          we will on commercial reasonable basis require from such third parties
          to adopt reasonable security standards to safeguard your information /
          data.
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          COOKIES AND OTHER TRACKING TECHNOLOGIES
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          Our Platform may utilize “cookies” and other Technical Information to
          track your usage of the Platform. “Cookies” are a small text file
          consisting of alphanumeric numbers used to collect the Information
          about Platform activity. Cookies in no way gives us access to your
          computer or mobile device. We only use session Cookies to keep a track
          of session information for persistence of authentication.
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          DATA RETENTION
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          We will retain your Information for as long as your Account is active
          or as needed to provide our Services or as required to maintain
          archival records of transactions. We shall retain and use the
          Information collected by us as necessary to comply with our legal
          obligations, resolve disputes or for other Legitimate Purposes. If you
          cancel/ deactivate/ unsubscribe your account with us, we are not under
          any obligation to retain your Information. However, we may retain the
          Information pertaining to the User for the maximum period permitted
          under the law of the land from the date of deactivation of User's
          Account.
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          SECURITY MEASURES AND SAFEGUARDS
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          You agree and accept that your Information may be stored in
          third-party cloud service infrastructure providers. While all
          reasonable attempts have been taken from our end to ensure the safe
          and secure storage of your data, we shall not be liable for any data
          breach on the part of the third-party cloud service infrastructure
          provider that was beyond our control. In addition to the security
          measures put in place by the third-party cloud service infrastructure
          provider for safe and secure storage of your Information, we use
          certain physical, managerial, technical or operational safeguards as
          per industry standards and established best practices to protect the
          Information we collect. We use reasonable security practices and
          procedures and use secure servers as mandated under applicable laws
          for the protection of your Information. We review our Information
          collection, storage, and processing practices, including physical
          security measures to guard against unauthorized access to systems.
          However, as effective as these measures are, no security system is
          impenetrable. We cannot guarantee the security of our database, nor
          can we guarantee that the Information you supply will not be
          intercepted while being transmitted to us over the internet. However,
          since the internet is not a 100% secure environment, we on best effort
          basis ensure the security of any information the User transmits to us
          and that the information may not be accessed, disclosed, altered, or
          destroyed by breach of any of our physical, technical, or managerial
          safeguards. Please note that e-mails and other communications the User
          sends to us through our Platform are not encrypted, and we strongly
          advise the User not to communicate any confidential information
          through these means.
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          UPDATES TO THIS PRIVACY POLICY
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          We may change the data privacy practices and update this Privacy
          Policy as and when the need arises, and the same will be made
          available on the Platform. But our commitment to protect your privacy
          will continue to remain. We suggest that you regularly check this
          Privacy Policy to apprise yourself of any updates. Your continued use
          of Platform and Services or provision of Information thereafter will
          imply your unconditional acceptance of such updates to this Privacy
          Policy. Further, we retain the right at any time to deny or suspend
          access to all, or any part of, the service to anyone who we reasonably
          believe has violated any provision of this Privacy Policy
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          MISCELLANEOUS
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          ENFORCEABILITY OF THE REMAINDER OF THIS PRIVACY POLICY. THIS PRIVACY
          POLICY DOES NOT APPLY TO ANY INFORMATION OTHER THAN THE INFORMATION
          COLLECTED BY US THROUGH THE PLATFORM. THIS PRIVACY POLICY SHALL BE
          INAPPLICABLE TO ANY UNSOLICITED INFORMATION YOU PROVIDE US THROUGH THE
          PLATFORM OR THROUGH ANY OTHER MEANS. ALL UNSOLICITED INFORMATION SHALL
          BE DEEMED TO BE NON-CONFIDENTIAL AND WE SHALL BE FREE TO USE AND/ OR
          DISCLOSE SUCH UNSOLICITED INFORMATION WITHOUT ANY LIMITATIONS. THE
          RIGHTS AND REMEDIES AVAILABLE UNDER THIS POLICY MAY BE EXERCISED AS
          OFTEN AS NECESSARY AND ARE CUMULATIVE AND NOT EXCLUSIVE OF RIGHTS OR
          REMEDIES PROVIDED BY LAW. RIGHTS UNDER THIS POLICY MAY BE WAIVED ONLY
          IN WRITING. DELAY IN EXERCISING OR NON-EXERCISE OF ANY SUCH RIGHT OR
          REMEDY DOES NOT CONSTITUTE A WAIVER OF THAT RIGHT OR REMEDY, OR ANY
          OTHER RIGHT OR REMEDY.
        </div>
      </div>
      <div class="mb-5">
        <div class="text-2xl not-italic font-bold font-sans mb-2.5">
          GRIEVANCE OFFICER
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          In furtherance of the Information Technology Act, 2000 (“IT Act”) and
          the Information Technology (Intermediary Guidelines and Digital Media
          Ethics Code) Rules, 2021 (“Intermediary Guidelines”) a grievance
          officer is appointed to ensure compliance with the IT Act and the
          Intermediary guidelines.
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-4">
          {/* Name: Himani Singh
          <br /> Address: Wework DLF two horizon centre, 5th floor, DLF phase 5
          sector 43, golf course road, Gurugram, Haryana */}
          <br /> Phone:&nbsp;
          <a
            href="tel:+91 9503182221"
            target="_blank"
            class="text-blue-600  undefined"
            rel="noreferrer"
          >
            +91 9503182221
          </a>
          <br /> Email:&nbsp;
          <a
            href="mailto:ultragonventures@gmail.com"
            target="_blank"
            class="text-blue-600 underline undefined"
            rel="noreferrer"
          >
            ultragonventures@gmail.com
          </a>
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-4">
          The grievance officer shall revert to every complaint within 24 hours
          of receipt of the complaint. Further, the Company shall take best
          possible efforts to redress the complaint within 15 days of receipt of
          the complaint. Any suggestions by Company regarding use of the
          Services shall not be construed as a warranty.
        </div>
        <div class="text-base not-italic font-normal font-sans text-black mb-2">
          Please feel free to reach out to us by e-mail at&nbsp;
          <a
            href="mailto:ultragonventures@gmail.com"
            target="_blank"
            class="text-blue-600 underline undefined"
            rel="noreferrer"
          >
            ultragonventures@gmail.com
          </a>
          &nbsp;in case of any concerns, grievances, or questions relating to
          our privacy or data related practices
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
