import './App.css'
import ComingSoon from './components/ComingSoon'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import TopFeaturesSection from './components/TopFeaturesSection'
// import Header from "./components/Header";
import HeroSection from './components/HeroSection'
import MobileHeader from './components/MobileHeader'
import PrivacyPolicy from './components/PrivacyPolicy'
// import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import ConnectWithBrands from './components/ConnectWithBrands'
import NextColabaration from './components/NextColabaration'
import OnePlatformSection from './components/OnePlatformSection'
import TheUltimateBrand from './page/TheUltimateBrand'
// import { app } from "./Firebase";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ComingSoon />
                <MobileHeader />
                <HeroSection />
                <TopFeaturesSection />
                <OnePlatformSection />
                <ConnectWithBrands />
                <NextColabaration />
                {/* <DiscoverCreatorSection />
                <AIConnectSection />
                <ManagePartnershipSection /> */}
                <ContactUsSection />
                <Footer />
              </>
            }
          />
          <Route path="/the-ultimate-brand" element={<TheUltimateBrand />} />
          <Route
            path="/privacy-policy"
            element={
              <>
                <MobileHeader />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  )
}

export default App
