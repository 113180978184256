import React, { useState } from 'react'
import ContactUs from '../assets/ContactUs.svg'
import ContactUs1 from '../assets/ContactUs1.svg'
import ContactUs3 from '../assets/ContactUs3.svg'
import SectionContainer from './SectionContainer'
import { Toast, showToast } from './Toast' // Import the Toast component and showToast function

const ContactUsSection = () => {
  const [email, setEmail] = useState('')

  const handleSubscribe = async () => {
    if (!email) {
      showToast('Please enter a valid email address.', 'warning')
      return
    }

    try {
      const response = await fetch('https://api.ultracreator.pro/api/subscribe/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })

      if (response.ok) {
        showToast('Thank you for subscribing!', 'success')
      } else {
        showToast('Failed to subscribe. Please try again.', 'error')
      }
    } catch (error) {
      showToast('An error occurred. Please try again.', 'error')
    }
  }

  return (
    <div className="contact-us-container">
      <SectionContainer>
        <div className="contact-us-main-container">
          <div className="image-container">
            <img src={ContactUs} width="100%" height="100%" alt="contact us girl" />
            <img src={ContactUs1} width="100%" height="100%" alt="contact us boy" className="contact-us-image" />
          </div>

          <div className="contact-us">
            <div className="contact-us-title">
              Get incredible stories in your <br /> inbox
            </div>
            <div className="input-container checkbox-margin">
              <div className="rl-input-section">
                <input
                  type="text"
                  className="input-section"
                  placeholder="youremail@example.com"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>

              <div className="rl-contact-us-btn-container">
                <button className="contact-us-btn" onClick={handleSubscribe}>
                  Subscribe
                </button>
              </div>
            </div>
          </div>
          <div className="contact-us-image">
            <img src={ContactUs3} width="100%" height="auto" alt="contact us boy" />
          </div>
        </div>
      </SectionContainer>
      <Toast /> {/* Include the Toast component */}
    </div>
  )
}

export default ContactUsSection
