import React from "react";
import Facebook from "../assets/facebook.svg";
import Twitter from "../assets/twitter.svg";
import LinkedIn from "../assets/linkedin.svg";
import YouTube from "../assets/youtube.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      {/* This is web section */}
      <div className="address-section">
        <div className="address-title">The Ultra Creator</div>
        <div className="address-subtitle">
          (+91) 950-318-2221 <br />
          Gurugram, India - 122001 <br />© 2024 Ultragon Ventures(OPC) Pvt. Ltd.
        </div>
      </div>
      <div className="menu-section">
        <ul>
          <Link to="/" className="link">
            <li>Home</li>
          </Link>
          <li>About us</li>
          <li>Get Started</li>
        </ul>
      </div>
      <div className="about-us-section">
        <ul>
          <li>FAQs</li>
          <li>Contact</li>
        </ul>
      </div>
      <div className="privacy-section">
        <ul>
          <li>
            <Link to="/privacy-policy" className="link">
              Privacy Policy
            </Link>
          </li>
          <li>Terms and Conditions</li>
        </ul>
      </div>

      {/* This is mobile section */}
      <div className="rl-footer-menu-container">
        <div className="rl-menu-section">
          <ul>
            <Link to="/" className="link">
              <li>Home</li>
            </Link>
            <li>About us</li>
            <li>Get Started</li>
          </ul>
        </div>
        <div className="rl-about-us-section">
          <ul>
            <li>FAQs</li>
            <li>Contact</li>
          </ul>
        </div>
        <div className="rl-privacy-section">
          <ul>
            <li>
              <Link to="/privacy-policy" className="link">
                Privacy Policy
              </Link>
            </li>
            <li>Terms and Conditions</li>
          </ul>
        </div>
      </div>
      <div className="social-connect-section">
        <a target="_blank" href="https://x.com/?lang=en&mx=2" rel="noreferrer">
          <img src={Twitter} className="social-icons" alt="brand logo" />
        </a>

        <a target="_blank" href="https://www.facebook.com/" rel="noreferrer">
          <img src={Facebook} className="social-icons" alt="brand logo" />
        </a>

        <a target="_blank" href="https://in.linkedin.com/" rel="noreferrer">
          <img src={LinkedIn} className="social-icons" alt="brand logo" />
        </a>
        <a target="_blank" href="https://www.youtube.com/" rel="noreferrer">
          <img src={YouTube} className="social-icons" alt="brand logo" />
        </a>
      </div>

      <div className="rl-footer-subtitle-container">
        <div className="rl-address-subtitle">
          (+91) 950-318-2221 <br />
          Gurugram, India - 122001 <br />© 2024 Ultragon Ventures(OPC) Pvt. Ltd.
        </div>
      </div>
    </div>
  );
};

export default Footer;
