import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    fontFamily: `Manrope`,
  },
  palette: {
    black: {
      main: '#000',
      contrastText: '#fff',
    },
    accent: {
      main: '#4CF23E',
    },
    primary: {
      main: '#9146FF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
})
